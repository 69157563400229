.nittro-paginator-previous {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  display: inherit;
  padding: inherit;
  border: inherit;
  flex-flow: inherit;
  justify-content: inherit;
  align-items: inherit;
  align-content: inherit;
  list-style: inherit;
  transform: translateX(-3000px);
  opacity: 0;
}
.nittro-paginator-previous::after {
  display: block;
  clear: both;
  height: 0;
  content: '';
}
