.nittro-dialog-holder {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  transition: height 0.5s step-end, background 0.5s cubic-bezier(0.27, 0.78, 0.2, 0.98);
}
.nittro-dialog-holder .nittro-dialog-inner {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  min-width: 300px;
  max-width: 96vw;
  max-height: 100vh;
  margin: 0 auto;
  padding: 0;
  overflow-y: auto;
  background: #fff;
  transform: translateY(-100%);
  transition: transform 0.3s ease-out;
}
.nittro-dialog-holder .nittro-dialog-inner .nittro-dialog-content {
  flex: 0 0 auto;
  padding: 30px 30px 15px;
}
.nittro-dialog-holder .nittro-dialog-inner .nittro-dialog-buttons {
  flex: 0 0 auto;
  padding: 15px 30px 30px;
  text-align: center;
}
.nittro-dialog-holder.visible {
  height: 100%;
  transition: height 0.3s step-start, background 1s ease-out;
}
.nittro-dialog-holder.visible:not(.busy) .nittro-dialog-inner {
  transform: translateY(0);
}
.nittro-dialog-holder.topmost {
  background: rgba(0, 0, 0, 0.6);
}
.nittro-dialog-holder.nittro-dialog-iframe .nittro-dialog-inner {
  flex: 1 1 auto;
}
.nittro-dialog-holder.nittro-dialog-iframe .nittro-dialog-inner .nittro-dialog-content {
  display: block;
  border: none;
  flex: 1 1 auto;
  margin: 0;
}
.nittro-dialog-holder.nittro-dialog-iframe.nittro-dialog-iframe-auto .nittro-dialog-inner {
  flex: 0 0 auto;
}
.nittro-dialog-holder.nittro-dialog-iframe.nittro-dialog-iframe-auto .nittro-dialog-inner .nittro-dialog-content {
  flex: 0 1 auto;
}
.nittro-dialog-holder.nittro-dialog-ios {
  position: absolute;
}
.nittro-dialog-holder.nittro-dialog-ios .nittro-dialog-inner {
  max-height: 100%;
}
